/// <reference path="../src/typings/tsd.d.ts" />

//@ts-ignore
// console.log(`%cinitializing moss-piglet ${ENVIRONMENT} build (${GIT_HASH} -- ${GIT_BRANCH}), ${BUILD_DATE}`, 'font-size: 20px');

import * as React from 'react';
import registerStores from '../src/stores/register-stores';
import initializePersistence from '../src/stores';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script'
import { Box, CircularProgress, LinearProgress } from '@mui/material';
import GenericPage from '../src/components/generics/layout/generic-page';
const isServer = typeof window === 'undefined'
//import AppView from '../src/containers/app';
import { HighlightInit } from '@highlight-run/next/client'
import { ErrorBoundary } from '@highlight-run/next/client';
import { Profiler, useEffect } from 'react';
//@ts-ignore
//import PubNub from 'pubnub';

//import * as Sentry from "@sentry/react";
//import { DiscoveryApi } from '../src/api/discovery-api';

//import { Provider } from 'jotai'
//import { createTheme, ThemeProvider } from '@mui/material';

if (process.browser) {
    //initializePersistence();
    //registerStores();
}

var rootInstance = null;

// function authenicateRoute() {
//     let state: {
//         user?: {
//             accessToken: string,
//             isAdmin?: boolean,
//             signupStep?: number
//         },
//         appState?: {
//             validLicense: boolean;
//         }
//     } = dispatcher.getState();

//     let {replace, push} = browserHistory,
//         {user, teams, cohorts} = state,
//         {isSignupOrSignin, isAcceptingInvite, location} = pathInfo(),
//         userIsSigningUp = (user && has(user, 'signupStep') && user.signupStep < 4),
//         userIsSignedIn = (user && has(user, 'accessToken') && user.accessToken && !userIsSigningUp),
//         userIsAdmin = (user && user.isAdmin),
//         userHasOrgs = (user && has(user, 'organizations')),
//         userHasActiveGroup = (user && user.groupID && (teams && teams[user.groupID] || cohorts && cohorts[user.groupID])),
//         createOrgRoute = 'create-organization',
//         manageOrgRoute = 'manage-organization';

//     if (isAcceptingInvite) return;

//     if (!userIsSignedIn && !isSignupOrSignin) {
//         // Store our original intended location in the cache here
//         //@ts-ignore
//        ((typeof window !== "undefined") ? window : {}).originalIntentionCache = location.url;

//         console.log("router is forwarding user to signin");

//         replace('/signin');
//     }else {
//         if ((location.url === '/' && userIsSignedIn) || (userIsSignedIn && isSignupOrSignin)) {
//             if (!userHasOrgs) {
//                 replace(`/no-organization`);
//             } else {
//                 replace('/canvas');
//             }
//         } else if (userIsSignedIn) {
//             // TODO: this routing should not have to specify profile, there should be a list of allowed routes for each case
//             if (!userHasOrgs && !location.is('\/no-organization|\/admin\/create-organization')) {
//                 replace('/no-organization');
//             } else if (!userHasActiveGroup) {
//                 if (userIsAdmin && !location.is('\/admin\/.*|\/profile')) {
//                     replace(`/admin/${manageOrgRoute}`);
//                 } else {
//                     // TODO: handle this case, probably some new view?
//                 }
//             }
//         }
//     } 
// }

// All of our content her is hard coded except the "release" stage.
// This doesn't differ or change in context of deployment
// and the key is designed to be in public hands, it's just for reporting data
// Exception begin the RELEASE_STAGE which is either dev (local), ci or prod.  
// That's handled in jenkins
let _releaseStage = 'dev';

// If the release stage is given then use it instead of the default 'dev'
//@ts-ignore
// if (!(RELEASE_STAGE === undefined || RELEASE_STAGE === null || RELEASE_STAGE === '')) {
//     //@ts-ignore
//     _releaseStage = RELEASE_STAGE;
// }

// Register our service workers
// if ('serviceWorker' in navigator) {
//     const registration = runtime.register();

//     registration.then((e) => {
//         // Check for a missing service worker
//         if (navigator.serviceWorker.controller === null || navigator.serviceWorker.controller === undefined) {
//             console.log('service worker requesting refresh of page');
//             //location.reload();
//         }

//         // Make sure we skip waiting on install
//         navigator.serviceWorker.controller.postMessage({
//             action: 'skipWaiting'
//         });

//         // Send our install message
//         navigator.serviceWorker.controller.postMessage({
//             cmd: 'install'
//         });

//         // Listen for incoming messages
//         navigator.serviceWorker.addEventListener('message', (event: any) => {
//             const {
//                 cmd
//             } = event.data;

//             // When there's an update to the savings state propegate it through our state
//             if (cmd === 'saving_update') {
//                 const newValue = (event.data.value === 'true');

//                 appActions.setShowSavingArtifactText(newValue);
//             }

//             if (cmd === 'show_conflict_alert') {
//                 const userNameOfConflict = event.data.value;

//                 appActions.setShowConflictPrompt(true, userNameOfConflict);
//             }
//         });
//     });
// }

// authenicateRoute();
//browserHistory.listen(authenicateRoute);



// (window as any).global = window;
// // @ts-ignore
// // window.Buffer =((typeof window !== "undefined") ? window : {}).Buffer || require('buffer').Buffer;

// if (typeof window !== "undefined") {
//     ((typeof window !== "undefined") ? document : {}).getElementById('root').style.height = '100%';
// }

// Setup sentry
// Sentry.init({ dsn: "https://804ab2fc5f4047d8b52703a30568d21e@sentry.io/1809170" });

// const theme = createTheme({
//     palette: {
//         primary: {
//             light: '#7fabc5',
//             main: '#1c445b',
//             dark: '#1c445b',
//             contrastText: '#fff',
//         },
//         secondary: {
//             light: '#FFA182',
//             main: '#f5835f',
//             dark: '#f5835f',
//             contrastText: '#000',
//         },
//     },
// });
function onRender(id, phase, actualDuration, baseDuration, startTime, commitTime) {
    // Aggregate or log render timings...
    // console.log(`${id}'s ${phase} phase: ${JSON.stringify({ actualDuration, baseDuration, startTime, commitTime })}}`);
}


function App({ Component, pageProps, cookies }) {
    const V3AppContainer = dynamic(() => import('../src/containers/app/v3-app-container'), {
        ssr: false,
    })

    // TODO V3.1, make this ssr
    const V3Layout = dynamic(() => import('../src/containers/app/layout'), {
        ssr: false,
    })

    return <>
        <HighlightInit
            projectId={'6glj9ld9'}
            tracingOrigins
            disableBackgroundRecording={true}
            environment={'production'}
            sessionShortcut="ctrl+alt+h"
            storageMode={'sessionStorage'}
            networkRecording={{
                enabled: true,
                recordHeadersAndBody: false,
                urlBlocklist: [
                    'https://ps19.pndsn.com',
                    'https://rest.ably.io',
                    'https://ps16.pndsn.com',
                    '*.pndsn.com'
                ],
            }}
        />
        <Profiler id="V3" onRender={onRender}>
        <GenericPage {...pageProps} cookies={cookies} >
            <V3Layout>
                <ErrorBoundary>
                    <V3AppContainer />
                </ErrorBoundary>
            </V3Layout>
        </GenericPage>
        </Profiler>
    </>
}

App.getInitialProps = async (context: any) => {
    // Extract cookies from AppContext
    return {
        cookies: context.req.cookies
    }
}

export default App;

// ReactDOM.render(
//     // <Sentry.ErrorBoundary fallback={<p>An error has occurred, please send this to support@innovationwithin.  You may want to try refreshing the page.</p>}>
//     <NoSSR>
//         <Sentry.ErrorBoundary
//             showDialog={false}
//             fallback={({ error, componentStack, resetError }) => (
//                 <div style={{
//                     width: '100%',
//                     height: '100%',
//                     backgroundColor: '#1f445a',
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                 }}>
//                     <div style={{
//                         width: '50%',
//                         backgroundColor: '#eeeeee',
//                         margin: 'auto',
//                         padding: '10px',
//                         textAlign: 'center',
//                         borderRadius: '3px',
//                     }}>
//                         <h2>Sorry, something went wrong</h2>
//                         <p style={{ marginBottom: '30px' }}>Our team has been notified of the issue and are looking into it. Sometimes small flukes can be resolved by simply <u>refreshing the page</u> and retrying the action.</p>
//                         <p style={{ marginBottom: '30px' }}>The button below will take you back to your last stable page, but if you run into a problem doing the same thing more than once, feel free to send us more details on your issue. This will help us diagnose and resolve your issue much faster!</p>
//                         <Accordion sx={{ marginBottom: '30px' }}>
//                             <AccordionSummary
//                                 expandIcon={<ExpandMoreIcon />}
//                                 aria-controls="error-details-form"
//                                 id="error-details-form"
//                             >
//                                 <Typography>Click here to send details about your recurring issue</Typography>
//                             </AccordionSummary>
//                             <AccordionDetails>
//                                 Sentry form
//                             </AccordionDetails>
//                         </Accordion>
//                         {/* <div>{error.toString()}</div> */}
//                         {/* <div>{componentStack}</div> */}
//                         <button
//                             onClick={() => {
//                                 {/* When resetError() is called it will remove the Fallback component */ }
//                                 {/* and render the Sentry ErrorBoundary's children in their initial state */ }
//                                 resetError();
//                             }}
//                         >
//                             Take me back to safety!
//                         </button>
//                     </div>
//                 </div>
//             )}
//         >
//             <PubNubProvider client={pubnub}>
//                 <Provider>
//                     <Router>
//                         <Routes>
//                             <Route element={<AppView />} path="/*">

//                             </Route>
//                         </Routes>
//                     </Router>
//                 </Provider>
//             </PubNubProvider>
//         </Sentry.ErrorBoundary></NoSSR>, ((typeof window !== "undefined") ? document : {}).getElementById('root'));
